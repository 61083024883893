<template>
  <div class="mb10 do_payment_target">
    <h2 class="content_title">{{ $t('msg.ONIM099P010.004') }}</h2> <!-- D/O 상태 -->
    <table class="tbl_col">
      <colgroup>
        <col width="25%">
        <col width="25%">
        <col width="25%">
        <col width="25%">
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('msg.ONIM099P010.005') }}</th> <!-- B/L No. -->
          <th>{{ $t('msg.ONIM099P010.006') }}</th> <!-- B/L 접수상태 -->
          <th>{{ $t('msg.ONIM099P010.007') }}</th> <!-- 운임(D/O 금액)/세금계산서 -->
          <th>{{ $t('msg.ONIM050G010.006') }}</th> <!-- Overfreetime Charge -->
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in formData.items">
          <tr :key="'main_' + index">
            <td>{{ item.blNo }}</td>
            <td>{{ item.blStsNm }}</td>
            <td>{{ item.frStsNm }}/{{ item.frIssNm }}</td>
            <td>{{ item.demStsNm }}</td>
          </tr>
        </template>
      </tbody>
    </table>

    <h2 class="content_title">{{ $t('msg.ONIM099P010.016') }}</h2> <!-- 청구처 선택 -->
    <table class="tbl_row">
      <colgroup>
        <col width="14%">
        <col width="86%">
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('msg.ONIM099P010.017') }}</th> <!-- 청구처 -->
          <td>
            <input type="text" class="col_3" id="reqArea" readonly v-model="formData.bzrgNo" @click.prevent="openPopup('business-number-pop')">
            <span class="ml2">
              <a class="button sm" @click.prevent="openPopup('business-number-pop')">{{ $t('msg.ONEX010T010.010') }}<!-- 검색 -->
              </a>
            </span>
            <!-- <span class="ml2">
              <a class="button sm" @click.prevent="initBzrgNo()">{{ $t('msg.MYIN040G010.034') }}
              </a>
            </span> -->
          </td>
        </tr>
      </tbody>
    </table>

    <h2 class="content_title">{{ $t('msg.ONIM099P010.019') }}</h2> <!-- D/O 발행 신청인 -->
    <table class="tbl_row">
      <colgroup>
        <col width="14%">
        <col width="36%">
        <col width="14%">
        <col width="36%">
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('msg.ONIM099P010.020') }}</th> <!-- 신청인 -->
          <td><input type="text" id="reqUsrNm" name="reqUsrNm" v-model="formData.reqUsrNm"></td>
          <th>{{ $t('msg.ONIM099P010.021') }}</th> <!-- 연락처 -->
          <td><input type="text" id="reqTelNo" name="reqTelNo" v-model="formData.reqTelNo"></td>
        </tr>
        <tr>
          <th>{{ $t('msg.ONIM099P010.022') }}</th> <!-- 위임장 -->
          <td colspan="3">
            <input type="text" class="col_6 delegateFile" id="delegateFile" name="delegateFile" :value="popupParams.length > 0 ? popupParams[0].originalName : ''" readonly="readonly">
            <span class="ml2">
              <a class="button sm" href="#" @click="openPopup('do-delegate-upload-pop')">{{ $t('msg.ONIM099P010.023') }}</a> <!-- 파일첨부 -->
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ $t('tit.CSBL400.005') }}</th> <!-- 요청내용 -->
          <td colspan="3">
            <textarea v-model="formData.reqCont"></textarea>
          </td>
        </tr>
        <tr>
          <th>{{ $t('msg.ONEX070G040.023') }}</th> <!-- B/L No. -->
          <td>
            {{ blNoStrings }}
          </td>
        </tr>
      </tbody>
    </table>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popupParams"
        :business-info="businessInfo"
        @close="closePopup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div><!-- popup_wrap // -->
</template>

<script>

import doPayment from '@/api/rest/trans/do'

export default {
  name: 'DOPaymentPop',
  components: {
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'business-number-pop': () => import('@/pages/user/popup/BusinessNumberPop'),
    'do-delegate-upload-pop': () => import('@/pages/trans/popup/DoDelegateUploadPop')
  },
  props: {
    blList: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      customComponent: null,
      selectFunc: null,
      popupParams: [],
      blNoStrings: '',
      formData: {
        blNoArr: [],
        items: [],
        reqUsrNm: '', //신청인
        reqTelNo: '', //연락처
        reqCont: '',
        uploadFileInfos: [],
        bsnNm: '',
        payType: '',
        reqRno: ''
      },
      businessInfo: {
        bzrgNo: '',
        bsnNm: ''
      }
    }
  },
  watch: {
    blList: {
      deep: true,
      handler () {
        this.init()
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      let resultArr = []
      this.blList.map((item) => {
        if (!resultArr.includes(item.blNo)) {
          resultArr.push(item.blNo)
        }
      })
      this.formData.blNoArr = resultArr
      //BL String화
      this.blNoStrings = this.formData.blNoArr.toString()

      const result = await doPayment.findDoPaymentDetail(this.formData)
      this.formData.items = result.data.map((item) => {
        //D/O별 미정산 금액
        doPayment.findUnpaid(item.blNo).then((i) => {
          let txtAmtUsd = 0
          let txtAmtLocal = 0
          if (i.data.length > 0) {
            i.data.map((amtItem) => {
              if (amtItem.occrCurCd === 'USD') {
                txtAmtUsd = this.$ekmtcCommon.changeNumberFormat(amtItem.amtUsd, { isComma: true }) || 0
              } else {
                txtAmtLocal = this.$ekmtcCommon.changeNumberFormat(amtItem.amtLocal, { isComma: true }) || 0
              }
            })
          }
          //미정산금액
          item.amt = 'USD ' + txtAmtUsd + '</br>' + 'KRW ' + txtAmtLocal
        })

        //ERROR 활성화 관련 플래그
        item.errorFlag = 'N'

        //상태코드별 B/L 접수상태
        let blSts = item.blSts
        if (blSts === 'A1') {
          item.blStsNm = this.$t('msg.ONIM099P010.024') //Original B/L 접수
        } else if (blSts === 'A2') {
          // item.blStsNm = this.$t('msg.ONIM099P010.025') //Bank B/L 접수
          item.blStsNm = this.$t('msg.ONIM010T010.016') // Bank L/G 접수
        } else if (blSts === 'A3') {
          item.blStsNm = this.$t('msg.ONIM099P010.026') //Surrender B/L 발행
        } else if (blSts === 'A4') {
          item.blStsNm = this.$t('msg.ONIM099P010.027') //Sea waybill 발행
        } else if (blSts === 'A5') {
          item.blStsNm = this.$t('msg.ONIM099P010.028') //Original B/L 미접수
        } else if (blSts === 'A6') {
          item.blStsNm = this.$t('msg.ONIM099P010.028') //Original B/L 미접수
          //item.blStsNm = this.$t('msg.ONIM099P010.029') //B/L 미발행
        } else if (blSts === 'A9') {
          item.blStsNm = this.$t('msg.ONIM099P010.062') //추후보완
        }

        let doStsCd = item.doStsCd
        if (doStsCd === '1' || doStsCd === '2') {
          item.doStsNm = this.$t('msg.ONIM099P010.030') //D/O 발행 - CS133I(DO정보)에 DO발행일자 존재 + CS114H(로그이력)에 LOG_CAT_CD(85) 정보 존재(X)
        } else if (doStsCd === '3') {
          item.doStsNm = this.$t('msg.ONIM099P010.031') //발행요청접수 - CM134I(요청정보)에서 APV_STS_CD가 02인 경우(X)
        } else if (doStsCd === '4') {
          item.doStsNm = this.$t('msg.ONIM099P010.032') //미발행
        } else if (doStsCd === '5') {
          item.doStsNm = this.$t('msg.ONIM099P010.033') //B/L 미접수 - CS132I(INBOUND B/L정보)에 BL회수일자가 없는 경우 or L/G 확인일자 없는 경우 or CS101M에 waybill 컨펌 없는 경우, 서렌더YN 컨펌 없는 경우
        } else if (doStsCd === '6') {
          item.doStsNm = this.$t('msg.ONIM099P010.034') //발행거절 - CM134I(요청정보)에서 APV_STS_CD가 04인 경우
        } else if (doStsCd === '7') {
          item.doStsNm = this.$t('msg.ONIM099P010.035') //발행가능 - CS132I(INBOUND B/L 정보) OBL확인일자 및 확인사용자번호 있는 경우
        } else if (doStsCd === '8') {
          item.doStsNm = this.$t('msg.ONIM099P010.036') //발행취소 - CS114(로그이력)에 취소로그(?) 이력 있는 경우
        } else if (doStsCd === '9') {
          item.doStsNm = this.$t('msg.ONIM099P010.037') //Pending - CM134I(요청정보)에서 APV_STS_CD가 07인 경우
        }

        //운임&세금계산서
        let tempNm1 = ''
        let frSts = item.frSts
        if (frSts === '01') {
          tempNm1 = this.$t('msg.ONIM099P010.038') //미정산(영수)
        } else if (frSts === '02') {
          tempNm1 = this.$t('msg.ONIM099P010.039') //미정산(개별청구)
        } else if (frSts === '03') {
          tempNm1 = this.$t('msg.ONIM099P010.040') //미정산(월말청구)
        } else if (frSts === '04') {
          tempNm1 = this.$t('msg.ONIM099P010.041') //외상한도 초과(개별청구)
        } else if (frSts === '11') {
          tempNm1 = this.$t('msg.ONIM099P010.042') //정산완료(영수)
        } else if (frSts === '12') {
          tempNm1 = this.$t('msg.ONIM099P010.043') //정산완료(개별청구)
        } else if (frSts === '13') {
          tempNm1 = this.$t('msg.ONIM099P010.044') //정산완료(월말청구)
        }

        let tempNm2 = ''
        this.$emit('setTaxInvoice', item.frIss)
        if (item.frIss === '01') {
          tempNm2 = this.$t('msg.ONIM099P010.045') //미발행
          this.$emit('setIsTaxValidCheck', true)
        } else {
          tempNm2 = this.$t('msg.ONIM099P010.046') //발행
        }

        item.frStsNm = tempNm1
        item.frIssNm = tempNm2

        //배정여부
        let alloSts = item.alloSts
        if (alloSts === 'Y') {
          item.alloStsNm = this.$t('msg.ONIM099P010.047') //배정 완료
        } else {
          item.alloStsNm = this.$t('msg.ONIM099P010.048') //미배정
        }

        //DEM상태
        let demSts = item.demSts
        if (demSts === 'Y') {
          item.demStsNm = this.$t('msg.ONIM099P010.049') //발생
        } else {
          item.demStsNm = this.$t('msg.ONIM099P010.050') //미발생
        }

        //정산 완료 및 세금계산서 발행 여부 플래그
        let completeCodes = ['11', '12', '13']
        if (completeCodes.indexOf(frSts) > -1 || item.frIss !== '01') {
          this.$emit('setPaidYn', true)
        }

        return item
      })
    },
    //에러보여주기
    errorShowing (item) {
      if (item.errorFlag === 'Y') {
        item.errorFlag = 'N'
      } else {
        item.errorFlag = 'Y'
      }
    },
    //D/O발행요청
    async fnPayment (obj) {
      //입금방식 셋팅
      this.formData.reqRno = obj.reqRno
      this.formData.payType = obj.type
      const result = await doPayment.insertDoRequest(this.formData)
      return result
    },
    //팝업 호출
    openPopup (name) {
      if (name === 'do-delegate-upload-pop') {
        this.selectFunc = this.getFileInfo
      } else {
        this.selectFunc = this.getBusinessNumber
      }
      this.customComponent = name
      this.$ekmtcCommon.layerOpen('.do_payment_target > .popup_dim')
    },
    getBusinessNumber (obj) {
      console.log(obj)
    },
    //파일업로드 팝업 종료 후 파일 셋팅
    getFileInfo (obj) {
      this.formData.uploadFileInfos = this.popupParams
      console.log('파일', obj)
    },
    //팝업 닫기
    closePopup (obj) {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.do_payment_target > .popup_dim')
      if (obj !== 'doDelegatePop') {
        if (obj) {
          this.formData.bzrgNo = obj.bzrgNo
          this.$emit('businessSetting', obj)
        }
      }
    },
    //초기화
    reset () {
      this.customComponent = null
      this.selectFunc = null
      this.popupParams = []
      this.formData = {
        blNoArr: [],
        items: [],
        reqUsrNm: '', //신청인
        reqTelNo: '', //연락처
        uploadFileInfos: [],
        bsnNm: '',
        payType: '',
        reqRno: ''
      }
      document.getElementById('reqArea').value = ''
    },
    emitData (type) {
      let res = null
      switch (type) {
        case 'do':
          res = JSON.parse(JSON.stringify(this.formData))
          break
      }
      return res
    },
    initBzrgNo () {
      let obj = {
        bzrgNo: '',
        telNo: '',
        addr: '',
        bsnNm: ''
      }

      this.formData.bzrgNo = ''
      this.$emit('initBusinessSetting', obj)
    },
    setRcqCont (reqCont, rcvProcCatCd) {
      console.log('>>>>> DOPaymentTarget : ' + reqCont + ', ' + rcvProcCatCd)
      if (rcvProcCatCd === '02') {
        if (this.formData.reqCont.indexOf(reqCont) < 0) {
          // 요청내용(선택) 값 변경
          this.formData.reqCont = reqCont + '\r\n' + this.formData.reqCont
        }
      }
    }
  }
}
</script>
