var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb10 do_payment_target" },
    [
      _c("h2", { staticClass: "content_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.ONIM099P010.004"))),
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "tbl_col" }, [
        _vm._m(0),
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.005")))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.006")))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.007")))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM050G010.006")))]),
          ]),
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.formData.items, function (item, index) {
              return [
                _c("tr", { key: "main_" + index }, [
                  _c("td", [_vm._v(_vm._s(item.blNo))]),
                  _c("td", [_vm._v(_vm._s(item.blStsNm))]),
                  _c("td", [
                    _vm._v(_vm._s(item.frStsNm) + "/" + _vm._s(item.frIssNm)),
                  ]),
                  _c("td", [_vm._v(_vm._s(item.demStsNm))]),
                ]),
              ]
            }),
          ],
          2
        ),
      ]),
      _c("h2", { staticClass: "content_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.ONIM099P010.016"))),
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "tbl_row" }, [
        _vm._m(1),
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.017")))]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.bzrgNo,
                    expression: "formData.bzrgNo",
                  },
                ],
                staticClass: "col_3",
                attrs: { type: "text", id: "reqArea", readonly: "" },
                domProps: { value: _vm.formData.bzrgNo },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openPopup("business-number-pop")
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "bzrgNo", $event.target.value)
                  },
                },
              }),
              _c("span", { staticClass: "ml2" }, [
                _c(
                  "a",
                  {
                    staticClass: "button sm",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openPopup("business-number-pop")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.010")))]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("h2", { staticClass: "content_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.ONIM099P010.019"))),
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "tbl_row" }, [
        _vm._m(2),
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.020")))]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.reqUsrNm,
                    expression: "formData.reqUsrNm",
                  },
                ],
                attrs: { type: "text", id: "reqUsrNm", name: "reqUsrNm" },
                domProps: { value: _vm.formData.reqUsrNm },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "reqUsrNm", $event.target.value)
                  },
                },
              }),
            ]),
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.021")))]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.reqTelNo,
                    expression: "formData.reqTelNo",
                  },
                ],
                attrs: { type: "text", id: "reqTelNo", name: "reqTelNo" },
                domProps: { value: _vm.formData.reqTelNo },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "reqTelNo", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.022")))]),
            _vm._v(" "),
            _c("td", { attrs: { colspan: "3" } }, [
              _c("input", {
                staticClass: "col_6 delegateFile",
                attrs: {
                  type: "text",
                  id: "delegateFile",
                  name: "delegateFile",
                  readonly: "readonly",
                },
                domProps: {
                  value:
                    _vm.popupParams.length > 0
                      ? _vm.popupParams[0].originalName
                      : "",
                },
              }),
              _c("span", { staticClass: "ml2" }, [
                _c(
                  "a",
                  {
                    staticClass: "button sm",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("do-delegate-upload-pop")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.023")))]
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("tit.CSBL400.005")))]),
            _vm._v(" "),
            _c("td", { attrs: { colspan: "3" } }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.reqCont,
                    expression: "formData.reqCont",
                  },
                ],
                domProps: { value: _vm.formData.reqCont },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "reqCont", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.023")))]),
            _vm._v(" "),
            _c("td", [_vm._v(" " + _vm._s(_vm.blNoStrings) + " ")]),
          ]),
        ]),
      ]),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: {
                  "parent-info": _vm.popupParams,
                  "business-info": _vm.businessInfo,
                },
                on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "86%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "36%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "36%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }